/*
 * @Author: 魏鑫
 * @Date: 2019-12-24 11:39:19
 * @LastEditors: 魏鑫
 * @LastEditTime: 2019-12-24 11:39:21
 */
import axios from '@/lib/api.request'
// 新保列表
export const xbList = data => axios.post('/hx/abtComHxRiskconList/xbList', data)
// 新保保单详情
export const xbShow = params => axios.get('/hx/abtComHxRiskconList/xbShow', {params})
