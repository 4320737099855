<!--
 * @Descripttion:
 * @version:
 * @Author: weixin
 * @Date: 2019-12-25 20:07:18
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-04 15:47:21
 -->

 <template>
   <div class="mainbody">
    <md-tabs>
        <div class="type-in">
          <md-field>
            <div class="extrafold" @click="showjb = !showjb">
              <p>基本信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showjb"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showjb"
              />
            </div>
            <div class="jbbr" v-show="showjb">
              <md-input-item
                title="保险公司："
                :solid="false"
                v-model="riskconList.baseidInfo.label"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="保单号："
                :solid="false"
                v-model="riskconList.policyno"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="投保单号："
                :solid="false"
                v-model="riskconList.appno"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="保费(元)："
                :solid="false"
                v-model="riskconList.mount"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="投保日期："
                :solid="false"
                v-model="riskconList.appdate"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="生效日期："
                :solid="false"
                v-model="riskconList.sxdate"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="承保日期："
                :solid="false"
                v-model="riskconList.cbdate"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="保单状态："
                :solid="false"
                v-model="riskconList.polistInfo.label"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="问题件产生时间："
                v-if="riskconList.polistInfo.value === '13'"
                :solid="false"
                v-model="riskconList.prointime"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="问题描述："
                v-if="riskconList.polistInfo.value === '13'"
                :solid="false"
                v-model="riskconList.probdesc"
                align="right"
                readonly
              ></md-input-item>
            </div>
            <div class="extrafold" @click="showxz = !showxz" >
              <p>险种信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showxz"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showxz"
              />
            </div>
            <div class="tbbr" v-show="showxz" v-for="(item,index) in classlist" :key="index + 'xz'">
                <md-input-item
                  title="险种名称："
                  :solid="false"
                  v-model="item.classname"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="主附险标志："
                  :solid="false"
                  v-model="item.appfInfo.label"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="缴费年限："
                  :solid="false"
                  v-model="item.yearnum"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="保障期间："
                  :solid="false"
                  v-model="item.bzname"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="保费："
                  :solid="false"
                  v-model="item.mount"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="保额："
                  :solid="false"
                  v-model="item.amount"
                  align="right"
                  readonly
                ></md-input-item>
            </div>

            <div class="extrafold" @click="showtb = !showtb">
              <p>投保人信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showtb"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showtb"
              />
            </div>
            <div class="tbbr" v-show="showtb" v-for="(item,index) in TB" :key="index + 'tb'">
              <md-input-item
                title="姓名："
                :solid="false"
                v-model="item.name"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="性别："
                :solid="false"
                name="sex"
                v-model="item.sexname"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="证件类型："
                :solid="false"
                name="cardtype"
                v-model="item.cardname"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="证件号码："
                :solid="false"
                name="cardnum"
                v-model="item.cardno"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="联系方式："
                :solid="false"
                v-model="item.tel"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="详细地址："
                :solid="false"
                v-model="item.addr"
                align="right"
                readonly
              ></md-input-item>
            </div>

              <!-- 被保人 -->
            <div class="extrafold" @click="showbb = !showbb">
              <p>被保人信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showbb"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showbb"
              />
            </div>
            <div class="tbbr" v-show="showbb" v-for="(item,index) in BB" :key="index + 'bb'">
              <md-input-item
                title="姓名："
                :solid="false"
                v-model="item.name"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="性别："
                :solid="false"
                v-model="item.sexname"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="证件类型："
                :solid="false"
                v-model="item.cardname"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="证件号码："
                :solid="false"
                name="cardnum"
                v-model="item.cardno"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="联系方式："
                :solid="false"
                v-model="item.tel"
                align="right"
                readonly
              ></md-input-item>
              <md-input-item
                title="详细地址："
                :solid="false"
                v-model="item.addr"
                align="right"
                readonly
              ></md-input-item>
               <md-input-item
                title="与投保人关系："
                :solid="false"
                v-model="item.relashipname"
                align="right"
                readonly
              ></md-input-item>

            </div>

            <div class="extrafold sy" @click="showsyr = !showsyr">
              <p>受益人信息</p>
              <img
                src="@/assets/abd/image/extraright1.png"
                style="width:6vw;height:4vw;padding-left:2vw"
                v-if="!showsyr"
              />
              <img
                src="@/assets/abd/image/extratobottom.png"
                style="width:5vw;height:6vw;"
                v-if="showsyr"
              />
            </div>
            <div v-show="showsyr">
                <md-input-item
                  title="受益人类型："
                  :solid="false"
                  v-model="riskconList.benetypeInfo.label"
                  align="right"
                  readonly
                ></md-input-item>
            </div>
            <div v-if="riskconList.benetypeInfo.label !== '法定'" v-show="showsyr">
              <div v-for="(item,index) in SY" :key="index">
                <md-input-item
                  title="姓名："
                  :solid="false"
                  v-model="item.name"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="性别："
                  :solid="false"
                  v-model="item.sexInfo.value"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="证件类型："
                  :solid="false"
                  v-model="item.cardInfo.value"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="证件号码："
                  :solid="false"
                  name="cardnum"
                  v-model="item.cardno"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="受益比例："
                  :solid="false"
                  name="syrate"
                  v-model="item.benerate"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="受益顺序："
                  :solid="false"
                  name="syorderno"
                  v-model="item.persortno"
                  align="right"
                  readonly
                ></md-input-item>
                <md-input-item
                  title="与被保人关系："
                  :solid="false"
                  v-model="item.beneshipname"
                  align="right"
                  readonly
                ></md-input-item>
              </div>
            </div>
          </md-field>
        </div>
      </md-tabs>
    </div>
 </template>

<script>
import { xbShow } from '@/api/hx/newpolicy/index'
export default {
  data () {
    return {
      showjb: true,
      showbb: false,
      showxz: false,
      showsyr: false,
      showtb: false,
      /* 保单详情 */
      policyList: {},
      riskconList: { baseidInfo: {}, polistInfo: {}, benetypeInfo: {} },
      classlist: [{ bzInfo: {}, appfInfo: {}, periodtypeMap: {} }],
      TB: [{ cardInfo: {}, sexInfo: {} }],
      SY: [{ cardInfo: {}, sexInfo: {} }],
      BB: [{ cardInfo: {}, sexInfo: {} }],
      spolicyno: this.$route.query.spolicyno,
      appfname: ''
    }
  },
  created () {
    this.policydetail()
  },
  methods: {
    policydetail () {
      let data = {
        spolicyno: this.spolicyno
      }
      xbShow(data).then(res => {
        this.riskconList = res.data.data
        this.riskconList.baseidInfo ? '' : this.riskconList.baseidInfo = { label: '', value: '' }
        this.riskconList.polistInfo ? '' : this.riskconList.polistInfo = { label: '', value: '' }
        this.riskconList.benetypeInfo ? '' : this.riskconList.benetypeInfo = { label: '', value: '' }
        this.classlist = res.data.data.classcodeList
        this.TB = res.data.data.tpersonList
        this.SY = res.data.data.beneficiaryList
        this.BB = res.data.data.bpersonList
        console.log(this.classlist)
        this.classlist.forEach(ele => {
          // ele.bzInfo ? '' : ele.bzInfo = { label: '', value: '' }
          ele.appfInfo ? '' : ele.appfInfo = { label: '', value: '' }
          ele.periodtypeMap ? '' : ele.periodtypeMap = { lable: '', value: '' }
          ele.period ? '' : ele.period = ''
          if (ele.bzyears != '') {
            if (ele.bzyears.indexOf('A') != -1) {
              console.log('1')
              ele.bzname = `保至${ele.bzyears.substring(0, ele.bzyears.length - 1)}岁`
            } else if (ele.bzyears.indexOf('E') != -1) {
              console.log('2')
              ele.bzname = '至终身'
            } else if (ele.bzyears.indexOf('Y') != -1) {
              console.log('3')
              ele.bzname = `保${ele.bzyears.substring(0, ele.bzyears.length - 1)}年`
            } else {
              console.log('4')
              ele.bzname = `保${ele.bzyears}年`
            }
          } else {
            ele.bzname = ''
          }
        })
        this.SY.forEach(ele => {
          ele.sexInfo.label ? '' : ele.sexInfo = { label: '', value: '' }
          ele.cardInfo.label ? '' : ele.cardInfo = { label: '', value: '' }
        })
      })
    }

  }

}
</script>

 <style lang="stylus" scoped>
    .mainbody{
      background-color  #EEEEEE
    }
  /deep/ .require {
    .md-field-item-title::after {
      content: '*';
      color: red;
    }
  }
  /deep/ .tjmes {
    .md-field-item-title {
      padding-left: 0;
      font-weight: bold;
    }
  }
  /deep/ .additionalrisks{
    .md-field-item-right {
      img{
        width 4vw
        height 4vw
      }
    }
  }
//   <!-- 附加险折叠 -->
  .extrafold{
    display flex
    height 8vh
    // border-top  10px solid #F8F8FA
    border-bottom  10px solid #F8F8FA
    justify-content space-between
    align-items center
    p{
      font-size 40px
      font-family 'PingFang-SC'
      font-weight bold
      color black
    }
      img{
        width 3vw
        height 4vw
      }
    }
  .extrat{
    border-top  20px solid #F8F8FA
  }
  .delextra{
    display flex
    justify-content center
    color #FE5257
    cursor pointer
  }
  .entry_btn{
    background-color #228BCF
    height 6vh
    border-radius 8px
    color white
    display flex
    justify-content center
    align-items center
    margin-top 2vh
    margin-bottom 2vh
  }
 </style>
